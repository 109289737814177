import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Event } from '../types/event'
import Spinner from '../components/Spinner/Spinner'
import Filter from '../components/Filter/Filter'
import EventCard from '../components/EventCard/EventCard'
import { ArrowClockwise, CalendarWeek, Send } from 'react-bootstrap-icons'
import Toolbar from '../components/Toolbar/Toolbar'
import { PUBLIC_EVENTS, EVENTS_FILTERS } from '../API'
import { filters } from '../types/filter'


const Events = ({ }) => {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<Event[]>([])
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([])


  const [filters, setFilters] = useState<filters[]>([])

  const sortEvents = (events: Event[]) => {
    const now = new Date()
    const openEvents = events
      .filter(event => new Date(`${event.Deadline}+03:00`) > now)
      .sort((a, b) => new Date(a.Deadline) > new Date(b.Deadline) ? 1 : -1)
    const closedEvents = events
      .filter(event => new Date(`${event.Deadline}+03:00`) < now)
      .sort((a, b) => new Date(a.Deadline) > new Date(b.Deadline) ? 1 : -1)
    return openEvents.concat(closedEvents)
  }
  const getEvents = async () => {
    const [
      content,
      filters
    ] = await Promise.all([
      fetch(PUBLIC_EVENTS({})).then((response) => response.json()),
      fetch(EVENTS_FILTERS).then((response) => response.json()),
    ])
    const events = sortEvents(content)
    setEvents(events)
    setFilteredEvents(events)
    setFilters(filters)
    setLoading(false)
  }
  useEffect(() => { getEvents() }, [])

  const reload = async () => {
    setLoading(true)
    await getEvents()
    setLoading(false)
  }

  const cbFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'OE': case 'LEE': case 'AEE': case 'Custom': case 'Public':
        setFilters(filters =>
          filters.map(filter => {
            if (filter.Name !== 'Type') return filter
            filter.Filters = filter.Filters.map(f => {
              if (f.Name === event.target.name) f.Status = event.target.checked
              return f
            })
            return filter
          }))
        break
      // default: return
    }
  }

  const doFilter = (filters: filters[]) => {
    const selectedStatuses = filters
      .find(f => f.Name === 'Status')
      ?.Filters
      .filter(f => f.Status)
      .map(f => f.Name)
    const selectedClassifications = filters
      .find(f => f.Name === 'Classification')
      ?.Filters
      .filter(f => f.Status)
      .map(f => f.Name)
    const selectedTypes = filters
      .find(f => f.Name === 'Type')
      ?.Filters
      .filter(f => f.Status)
      .map(f => f.Name)
    setFilteredEvents(
      events
        .filter(event =>
          selectedStatuses
            ? selectedStatuses.includes(new Date(event.Deadline) > new Date() ? 'Open' : 'Closed')
            : true)
        .filter(event =>
          selectedClassifications
            ? selectedClassifications.includes(event.Classification.toString())
            : true)
        .filter(event =>
          selectedTypes
            ? selectedTypes.includes(event.Type.toString())
            : true)
    )
  }

  useEffect(() => { doFilter(filters) }, [filters])

  if (loading) return <Spinner />
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Offerings</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Filter
            filters={filters}
            setFilters={setFilters}
          />
          <Link className="btn btn-primary" to="/calendar" title="Calendar">
            <CalendarWeek />
            <div><small>Calendar</small></div>
          </Link>
        </Toolbar>
      </div>
      <div className="d-flex flex-wrap flex-column flex-sm-row justify-content-center align-items-center position-relative mb-2">
        <Form.Label className="filter-element rounded-pill d-flex justify-content-between m-1">
          Open Enrollment (OE)
          <Form.Check type="switch" className="d-inline-block" name="OE" onChange={cbFilter} checked={
            filters.find(f => f.Name === 'Type')?.Filters.find(f => f.Name === 'OE')?.Status
          } />
        </Form.Label>
        <Form.Label className="filter-element rounded-pill d-flex justify-content-between m-1">
          Local Executive Education (LEE)
          <Form.Check type="switch" className="d-inline-block" name="LEE" onChange={cbFilter} checked={
            filters.find(f => f.Name === 'Type')?.Filters.find(f => f.Name === 'LEE')?.Status
          } />
        </Form.Label>
        <Form.Label className="filter-element rounded-pill d-flex justify-content-between m-1">
          Abroad Executive Education (AEE)
          <Form.Check type="switch" className="d-inline-block" name="AEE" onChange={cbFilter} checked={
            filters.find(f => f.Name === 'Type')?.Filters.find(f => f.Name === 'AEE')?.Status
          } />
        </Form.Label>
        <Form.Label className="filter-element rounded-pill d-flex justify-content-between m-1">
          Custom Program (CP)
          <Form.Check type="switch" className="d-inline-block" name="Custom" onChange={cbFilter} checked={
            filters.find(f => f.Name === 'Type')?.Filters.find(f => f.Name === 'Custom')?.Status
          } />
        </Form.Label>
        <Form.Label className="filter-element rounded-pill d-flex justify-content-between m-1">
          Public (P)
          <Form.Check type="switch" className="d-inline-block" name="Public" onChange={cbFilter} checked={
            filters.find(f => f.Name === 'Type')?.Filters.find(f => f.Name === 'Public')?.Status
          } />
        </Form.Label>
      </div>
      <div className="d-flex align-items-stretch justify-content-center flex-wrap gap-4">
        {filteredEvents.map((event) => (
          <EventCard event={event} key={`event-${event.Id}`} />
        ))}
      </div>
    </>
  )
}

export default Events
