import * as React from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { ColumnDef } from '@tanstack/react-table'
import { dateFilter } from '../utils/filters'
import { OrganizationListView } from '../types/profile'
import { submitForm } from '../utils/forms'
import { ORGANIZATION_CONTRIBUTOR } from '../API'
import { Button } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import Organization from '../components/Forms/Organization'

type OrganizationsTableColumnsProps = {
  onStatusChange: React.ChangeEventHandler<HTMLSelectElement>
  deleteOrganization: React.MouseEventHandler<HTMLButtonElement>
}

const STATUS = {
  'null': 'Pending',
  'true': 'Accepted',
  'false': 'Not Accepted',
}

const organizationsTableColumns = ({ onStatusChange, deleteOrganization }: OrganizationsTableColumnsProps): ColumnDef<OrganizationListView>[] => [
  {
    header: () => <span>Organization Name</span>,
    accessorKey: 'NameEn',
    accessorFn: row => row.NameEn,
    id: 'NameEn',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>HR Representative Name</span>,
    accessorKey: 'Representative1Name',
    accessorFn: row => row.Representative1Name,
    id: 'Representative1Name',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>HR Representative Telephone</span>,
    accessorKey: 'Representative1Phone',
    accessorFn: row => row.Representative1Phone,
    id: 'Representative1Phone',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>HR Representative Email</span>,
    accessorKey: 'Representative1Email',
    accessorFn: row => row.Representative1Email,
    id: 'Representative1Email',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Applied On</span>,
    accessorKey: 'CreatedOn',
    accessorFn: row => row.CreatedOn,
    id: 'CreatedOn',
    cell: info => new Date(info.getValue<string>()).toLocaleDateString('en-GB'),
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>Status</span>,
    accessorKey: 'IsApproved',
    accessorFn: row => ({ Id: row.Id, IsApproved: row.IsApproved }),
    id: 'IsApproved',
    cell: info => {
      const { Id, IsApproved } = info.getValue<{ Id: number, IsApproved: boolean }>()
      return (
        <Form.Select
          id={`${Id}`}
          data-id={`${Id}`}
          aria-label="status"
          onChange={onStatusChange}
          defaultValue={
            IsApproved == null
              ? 'pending'
              : IsApproved
                ? 'accepted'
                : 'not accepted'
          }
          className={`${IsApproved !== null && 'disabled'}`}
          style={{ minWidth: '128px' }}
        >
          <option value="pending">Pending</option>
          <option value="accepted">Accepted</option>
          <option value="not accepted">Not Accepted</option>
        </Form.Select>
      )
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue<any>(columnId).IsApproved
      const statusString = STATUS[value as keyof typeof STATUS].toLowerCase()
      return Boolean(statusString.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = STATUS[current.original.IsApproved as keyof typeof STATUS].toLowerCase()
      const nextStatus = STATUS[next.original.IsApproved as keyof typeof STATUS].toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Contributor</span>,
    accessorKey: 'Id',
    accessorFn: row => ({ Organization: row.Id, Contributor: row.IsContributor }),
    id: 'Contributor',
    cell: info => {
      const { Organization, Contributor } = info.getValue() as { Organization: string, Contributor: boolean }
      return (
        <Form.Check
          onChange={async event => {
            event.target.disabled = true
            const { checked } = event.target
            const formData = new FormData()
            formData.set('Organization', Organization)
            formData.set('Contributor', `${checked}`)
            const json = await submitForm(ORGANIZATION_CONTRIBUTOR, formData)
            event.target.checked = json.type === 'SUCCESS'
              ? checked
              : !checked
            event.target.disabled = false
          }}
          type="switch"
          id={`contributor-${Organization}`}
          name="Contributor"
          title="Contributor"
          defaultChecked={Contributor}
          data-id={`${Organization}`}
        />
      )
    },
    enableColumnFilter: false,
    sortingFn: (current: any, next: any) => {
      const currentContributor = current.original.IsContributor
      const nextContributor = next.original.IsContributor
      return currentContributor === nextContributor
        ? 0
        : currentContributor > nextContributor
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Organization Profile</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Id',
    cell: info => <Link to={`/hr/profile/${info.getValue()}`}>Profile</Link>,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    header: () => <span>Actions</span>,
    accessorKey: 'Id',
    accessorFn: row => ({ Organization: row.Id, IsApproved: row.IsApproved }),
    id: 'Actions',
    cell: info => {
      const { Organization, IsApproved } = info.getValue() as { Organization: string, IsApproved: boolean }
      return (
        <div className="text-nowrap">
          <Button
            className="p-0 p-2 btn-action border-0 bg-transparent"
            data-id={Organization}
            onClick={deleteOrganization}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
            disabled={IsApproved}
          >
            <Trash size={25} />
          </Button>
        </div>
      )
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  STATUS,
  organizationsTableColumns,
}