import './custom.css'
import * as React from 'react'
import { Routes, Route, Navigate } from 'react-router'
import Layout from './layout/Layout'
import Home from './pages/Home'
import LogIn from './pages/LogIn'
import FAQ from './pages/FAQ'
import Counter from './components/Counter'
import Register from './pages/Register'
import Events from './pages/Events'
import EventDetails from './pages/EventDetails'
import Profile from './pages/user/Profile'
import Feedback from './pages/Feedback'
import NewsFeed from './pages/NewsFeed'
import Article from './components/Newsfeed/Article'
import Calendar from './pages/Calendar'
import TermsAndConditions from './pages/TermsAndConditions'
import ResetPassword from './pages/ResetPassword'
import Dashboard from './pages/shared/Dashboard'
import EventList from './pages/admin/EventList'
import EventInfo from './pages/admin/EventInfo'
import EventApplication from './pages/EventApplication'
import NewsfeedList from './pages/admin/NewsfeedList'
import ArticleInfo from './pages/admin/ArticleInfo'
import AllApplicants from './pages/admin/AllApplicants'
import AcceptedApplicants from './pages/admin/AcceptedApplicants'
import Scoring from './pages/admin/Scoring'
import Application from './pages/admin/Application'
import FeedbackReport from './pages/admin/FeedbackReport'
import Organizations from './pages/admin/Organizations'
import Individuals from './pages/admin/Individuals'
import Attendance from './pages/admin/Attendance'
import Subscribers from './pages/admin/Subscribers'
import ApplicantsDashboard from './pages/admin/ApplicantsDashboard'
import AdministratorReports from './pages/admin/Reports'
import Criterion from './pages/admin/Criterion'
import Criteria from './pages/admin/Criteria'
import Approvals from './pages/hr/Approvals'
import Employees from './pages/hr/Employees'
import HrProfile from './pages/hr/Profile'
import EmployeeApplication from './pages/hr/EmployeeApplication'
import HrReports from './pages/hr/Reports'
import Applications from './pages/user/Applications'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { getAuth, setAuth } from './store/AuthSlice'
import { GET_ACCOUNT } from './API'

const AppRoutes: React.FC = ({ }) => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(selector => selector.auth.state)
  React.useEffect(() => {
    (async () => {
      const response = await fetch(GET_ACCOUNT)
      const isAuthenticated = await response.json()
      isAuthenticated
        ? dispatch(getAuth())
        : dispatch(setAuth({
          state: 'LOGGED_OUT',
          role: 'PUBLIC',
          token: null,
          status: 'IDLE'
        }))
    })()
  }, [state])
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/counter" element={<Counter />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/register/:type" element={<Register />} />
        <Route path="/faq" element={<FAQ />} />
        {/* TODO: DELETE THIS */}
        <Route path="/profile/:id" element={<Profile />} />
        {/* <Route path="/fetch-data" element={<FetchData />} /> */}
        {/* <Route path="/fetch-data/:startDateIndex" element={<FetchData />} /> */}
        <Route path="/event-list" element={<Events />} />
        <Route path="/event-list/:id" element={<EventDetails />} />
        <Route path="/newsfeed" element={<NewsFeed />} />
        <Route path="/newsfeed/:id" element={<Article />} />
        <Route path="/feedback/:id" element={<Feedback />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/reset-password/:id/:code" element={<ResetPassword />} />
        {/* SHARED */}
        {state === "LOGGED_IN" && (
          <>
            <Route
              path="/event-list/:id/application"
              element={<EventApplication />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* ADMIN */}
            <Route path="/admin/event-list" element={<EventList />} />
            <Route path="/admin/event-info/:id" element={<EventInfo />} />
            <Route path="/admin/feedback/:id" element={<FeedbackReport />} />
            <Route path="/admin/newsfeed-list" element={<NewsfeedList />} />
            <Route path="/admin/article-info/:id" element={<ArticleInfo />} />
            <Route path="/admin/attendance/:id" element={<Attendance />} />
            <Route path="/admin/organizations" element={<Organizations />} />
            <Route path="/admin/individuals" element={<Individuals />} />
            <Route
              path="/admin/event-info/:id/all-applicants"
              element={<AllApplicants />}
            />
            <Route
              path="/admin/event-info/:id/accepted-applicants"
              element={<AcceptedApplicants />}
            />
            <Route path="/admin/event-info/:id/scoring" element={<Scoring />} />
            <Route
              path="/admin/event-info/:id/applicant-dashboard"
              element={<ApplicantsDashboard />}
            />
            <Route path="/admin/application/:id" element={<Application />} />
            <Route path="/admin/subscribers" element={<Subscribers />} />
            <Route path="/admin/criterion" element={<Criterion />} />
            <Route path="/admin/criteria/:id" element={<Criteria />} />
            <Route path="/admin/reports" element={<AdministratorReports />} />
            {/* HR ADMIN */}
            <Route path="/hr/profile/:id" element={<HrProfile />} />
            <Route path="/hr/approvals" element={<Approvals />} />
            <Route path="/hr/employees/:id" element={<Employees />} />
            <Route path="/hr/application/:id" element={<EmployeeApplication />} />
            <Route path="/hr/reports" element={<HrReports />} />
            {/* USER */}
            <Route path="/applications" element={<Applications />} />
            <Route path="/applications/:id" element={<Applications />} />
            <Route path="/profile" element={<Profile />} />
          </>
        )}
        {/* DEFAULT */}
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </Layout>
  )
}

export default AppRoutes
