import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../custom.react-big-calendar.css'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import {
  Calendar as BigCalendar,
  momentLocalizer,
  View,
  Event as BigCalendarEvent,
} from 'react-big-calendar'
import moment from 'moment'
import Spinner from '../components/Spinner/Spinner'
import { Event } from '../types/event'
import { Button } from 'react-bootstrap'
import { ArrowClockwise, CalendarEvent, Printer } from 'react-bootstrap-icons'
import Toolbar from '../components/Toolbar/Toolbar'
import { PUBLIC_EVENTS } from '../API'

type CalendarEvent = BigCalendarEvent & { id: number }

moment.defineLocale('en-GB', {
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
})

const Calendar = () => {
  const [loading, setLoading] = useState(true)
  const [calendarEvents, setCalendarEvents] = useState<Array<CalendarEvent>>([])
  const navigate = useNavigate()
  const handlePrint = () => { window.print() }
  const localizer = momentLocalizer(moment)
  const allViews: Array<View> = ['agenda', 'day', 'week', 'month']
  const eventStyleGetter = () => {
    const style = {
      backgroundColor: 'var(--kfas-orange-dark)',
      borderRadius: '0',
      opacity: 0.8,
      color: 'var(--kfas-white)',
      border: '0',
    }
    return { style }
  }

  const getEvents = async () => {
    const [eventsData]: [Array<Event>] = await Promise.all([
      fetch(PUBLIC_EVENTS``).then((response) => response.json()),
    ])
    setCalendarEvents(eventsData.map(event => ({
      id: event.Id,
      title: event.Title,
      start: new Date(`${event.FromDate.split('T')[0]}T${event.StartTime.split('.')[0]}+03:00`),
      end: new Date(`${event.ToDate.split('T')[0]}T${event.EndTime.split('.')[0]}+03:00`),
      allDay: false,
    })))
    setLoading(false)
  }
  useEffect(() => { getEvents() }, [])

  const reload = async () => {
    setLoading(true)
    await getEvents()
    setLoading(false)
  }

  const onSelect = (event: CalendarEvent) => { navigate(`/event-list/${event.id}`) }

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Calendar</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Button onClick={handlePrint}>
            <Printer />
            <div><small>Print</small></div>
          </Button>
        </Toolbar>
      </div>
      <BigCalendar
        localizer={localizer}
        events={calendarEvents}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={onSelect}
        views={allViews}
        startAccessor="start"
        endAccessor="end"
        step={60}
        showMultiDayTimes
        style={{ height: "600px" }}
      />
    </>
  )
}

export default Calendar
