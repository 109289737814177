import './Sidebar.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Building,
  CalendarDate,
  Clipboard2Data,
  ColumnsGap,
  Files,
  Newspaper,
  People,
  Person,
  PersonCheck
} from 'react-bootstrap-icons'
import {
  Col,
  Container,
  Offcanvas,
  Row
} from 'react-bootstrap'
import { USER_ROLE } from '../../API'

type SidebarProps = {
  show: boolean
  onHide: () => void
}

const Sidebar = ({ show, onHide }: SidebarProps) => {
  const [role, setRole] = useState('')

  const getUserRole = async () => {
    const rawResponse = await fetch(USER_ROLE)
    setRole(await rawResponse.text())
  }
  useEffect(() => { getUserRole() }, [])

  const isDisabled = (path: string) => {
    const pathName = window.location.pathname
    return ` ${pathName === path ? 'text-white-50 disabled' : 'text-white'}`
  }

  return (
    <Offcanvas show={show} onHide={onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h4 className="text-primary p-0 m-0">Menu</h4>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          {role == "admin" &&
            <>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/dashboard')}`} to="/dashboard" title="Dashboard">
                    <ColumnsGap />
                    <div><small>Dashboard</small></div>
                  </Link>
                </Col>
              </Row>
              <h5 className="text-primary">Admin</h5>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/admin/event-list')}`} to="/admin/event-list" title="Events">
                    <CalendarDate />
                    <div><small>Offerings</small></div>
                  </Link>
                </Col>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/admin/newsfeed-list')}`} to="/admin/newsfeed-list" title="Newsfeed">
                    <Newspaper />
                    <div><small>Newsfeed</small></div>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/admin/organizations')}`} to="/admin/organizations" title="Organizations">
                    <People />
                    <div><small>Organizations</small></div>
                  </Link>
                </Col>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/admin/individuals')}`} to="/admin/individuals" title="Individuals">
                    <Person />
                    <div><small>Individuals</small></div>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/admin/reports')}`} to="/admin/reports" title="Reports">
                    <Clipboard2Data />
                    <div><small>Reports</small></div>
                  </Link>
                </Col>
                <Col></Col>
              </Row>
            </>}
          {role == "publicAdmin" &&
            <>
              <h5 className="text-primary">Admin</h5>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/admin/event-list')}`} to="/admin/event-list" title="Offerings">
                    <CalendarDate />
                    <div><small>Offerings</small></div>
                  </Link>
                </Col>
                <Col></Col>
              </Row>
            </>}
          {(role == "representative" || role == "admin") &&
            <>
              <h5 className="text-primary">HR</h5>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/hr/approvals')}`} to="/hr/approvals" title="Approvals">
                    <PersonCheck />
                    <div><small>Approvals</small></div>
                  </Link>
                </Col>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/hr/employees/:id')}`} to="/hr/employees/:id" title="Employees">
                    <People />
                    <div><small>Employees</small></div>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/hr/profile/:id')}`} to="/hr/profile/:id" title="Organization Profile">
                    <Building />
                    <div><small>Organization Profile</small></div>
                  </Link>
                </Col>
                <Col>
                  <Link className={`btn btn-primary w-100 my-2${isDisabled('/hr/reports')}`} to="/hr/reports" title="Reports">
                    <Clipboard2Data />
                    <div><small>Reports</small></div>
                  </Link>
                </Col>
              </Row>
            </>}
          <h5 className="text-primary">User</h5>
          <Row>
            <Col>
              <Link className={`btn btn-primary w-100 my-2${isDisabled('/profile/:id')}`} to="/profile/:id" title="Profile">
                <Person />
                <div><small>Profile</small></div>
              </Link>
            </Col>
            <Col>
              <Link className={`btn btn-primary w-100 my-2${isDisabled('/applications')}`} to="/applications" title="Applications">
                <Files />
                <div><small>Applications</small></div>
              </Link>
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
    </Offcanvas >
  )
}

export default Sidebar