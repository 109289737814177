import * as React from 'react'
import { useEffect, useState, createRef } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from '../components/Spinner/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { submitForm } from '../utils/forms'
import data from './shared/Data.en.json'
import { UserProfile } from '../types/profile'
import { EventExtraData } from '../types/event'
import { APPLICATION_REGISTRATION, EVENT_EXTRA_DATA, EVENT_TYPE, EVENT_VALIDITY, USER_PROFILE } from '../API'

const policies: string[] = [
  // 'Seats are limited and therefore acceptance is based on the competitiveness of your application.',
  // 'OE and LEE seats are reserved for Kuwaiti nationals from all sectors (private and public). Any remainder seats, will be made available to non-Kuwaitis.',
  // 'AEE & Custom Program seats are exclusively reserved for Kuwaiti nationals.',
  // 'Applicants are only eligible for one seat, per program type (OE/AEE/LEE), per year.',
  // 'Applications involve answering questions about yourself, your organization, uploading documents and agreeing to terms and conditions.',
  // 'All applications are reviewed and processed after the application deadline.',
  // 'Your application will first be blind reviewed by a committee, then confirmed by the partnering school/training provider.',
]
const suggestions: string[] = [
  // 'Make sure your English is correct with proper grammar, punctuation and spelling.',
  // 'Avoid bullet point answers. Complete sentences are preferred.',
  // 'Do not copy-paste from your resume or CV!',
  // 'Keep your answers simple, concise, and sufficient. Aim for complete sentences. One-word answers will not be considered.',
  // 'Please ensure your answers are directly relevant to the question.',
  // 'Each question will be scored by the blind committee based on the quality of your answers (1-3 points per question).',
]
const checkboxLabels: string[] = [
  'I have reviewed and confirmed all information on my application is correct to the best of my knowledge, and that I will not be able to amend my application after submitting.',
  'I accept the terms and conditions.',
  'I have read and understood the application guidelines.',
  'I hereby give my consent to KFAS and its affiliates and agents to use my image and likeness, and/or any interview statements from me in its publications, advertising or other media activities (including the Internet-based activities).',
]

type Props = {}
type ObjectKey = keyof typeof data
const EventApplication = (props: Props) => {
  const OE = 1
  const AEE = 2
  const LEE = 3
  const { id: eventId } = useParams()
  const navigate = useNavigate()
  const [eventType, setEventType] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [alert, setAlert] = useState<string>('')
  const [userProfile, setUserProfile] = useState<UserProfile>()
  const formRef = createRef<HTMLFormElement>()
  const [eventExtraData, setEventExtraData] = useState<EventExtraData>()

  const checkEvent = async () => {
    const [isEventValid, eventTypeData, userProfileData, eventExtraData] = await Promise.all([
      fetch(EVENT_VALIDITY`${eventId ?? ''}`)
        .then((response) => response.json()),
      fetch(EVENT_TYPE`${eventId ?? ''}`)
        .then((response) => response.json()),
      fetch(USER_PROFILE``)
        .then((response) => response.json()),
      fetch(EVENT_EXTRA_DATA`${eventId ?? ''}`)
        .then((response) => response.json()),
    ])
    setEventType(eventTypeData.Id)
    setUserProfile(userProfileData)
    setEventExtraData(eventExtraData)
    switch (eventTypeData.Id) {
      case 1:
        Object.assign(policies, [
          'Seats are limited and therefore acceptance is based on competitiveness of your application.',
          'All seats are for Kuwaitis from private and public companies.',
          'Applications involve answering questions about yourself, your organization, uploading documents and agreeing to terms and conditions.',
          'All applications are reviewed and processed after the application deadline.',
        ])
        break
      case 2:
      case 3:
        Object.assign(policies, [
          '<b>Nationality:</b> All seats are for Kuwaitis from shareholding companies contributing to KFAS.',
          '<b>Education:</b> University level education minimum Bachelors degree. ',
          '<b>English proficiency:</b> The candidate should be proficient in English.',
          '<b>Function:</b> Applicant function will be evaluated based on the program they are applying to and will be subject to review.',
        ])
        break
    }
    if (isEventValid) setLoading(false)
    else navigate(-1)
  }
  useEffect(() => { checkEvent() }, [eventId])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const json = await submitForm(APPLICATION_REGISTRATION, formData)
    if (json.type === 'SUCCESS') navigate('/applications')
    else setLoading(false)
    let alertHtml = `<div>${json.message}</div>`
    setAlert(alertHtml)
    if (!json.errors) {
      return
    }
    alertHtml += '<ul class="m-0">'
    for (const error of json.errors) {
      alertHtml += `<li>${error.Code}: ${error.Description}</li>`
    }
    alertHtml += '</ul>'
    setAlert(alertHtml)
  }

  if (loading) return <Spinner />
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Event Application</h1>
      {alert.length > 0 && (
        <div
          className="alert alert-danger"
          role="alert"
          dangerouslySetInnerHTML={{ __html: alert }}
        />
      )}
      {userProfile?.Verified === false &&
        <Alert variant="danger">
          Your profile is not verified by your HR Representative(s), you will not be
          able to register until it is verified, please contact your HR Representative(s) to get verified.
        </Alert>
      }
      <Alert variant="info">
        Please make sure the information in your profile is correct and
        accurate before proceeding.
      </Alert>
      {/* POLICIES */}
      {policies.length > 0 && (
        <>
          <h4 className="mb-4 text-capitalize">Policies</h4>
          {/* <p>
            As KFAS adopts a new direction emphasizing nationalization, please find
            below our new seat policies:
          </p> */}
          {/* 
            1: OE
            2: AEE
            3: LEE
          */}
          {eventType === OE ? (
            <p>
              Please find below our seat policies for Open Enrollment programs.
            </p>
          ) : (
            <p>
              The selection of nominees will be on competitive basis. Therefore,
              it is important to provide accurate information when submitting
              your application.
            </p>
          )}
          <ListGroup className="mb-4">
            {policies.map((policy, i) => (
              <ListGroup.Item
                key={`${policy}-${i}`}
                dangerouslySetInnerHTML={{ __html: policy }}
              />
            ))}
          </ListGroup>
        </>
      )}
      {/* SUGGESTIONS */}
      {suggestions.length > 0 && (
        <>
          <h4 className="mb-4 text-capitalize">Suggestions</h4>
          <p>
            Here are some suggestions to ensure your application is as
            competitive as possible:
          </p>
          <ListGroup className="mb-4">
            {suggestions.map((suggestion, i) => (
              <ListGroup.Item
                key={`${suggestion}-${i}`}
                dangerouslySetInnerHTML={{ __html: suggestion }}
              />
            ))}
          </ListGroup>
        </>
      )}
      {/* QUESTIONS */}
      <Form
        action={APPLICATION_REGISTRATION}
        method="post"
        onSubmit={onSubmit}
        ref={formRef}
      >
        <Form.Group className="mb-3">
          <Form.Label htmlFor="Experience">Years of Experience</Form.Label>
          <Form.Control
            type="number"
            id="Experience"
            name="Experience"
            aria-describedby="experience"
            className="mb-3"
            required
          />
        </Form.Group>
        {/* 
          1: OE
          2: AEE
          3: LEE
         */}
        {(eventType === OE || eventType === AEE || eventType === LEE) && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="ManagerialExperience">
                Years of Managerial Experience
              </Form.Label>
              <Form.Control
                type="number"
                id="ManagerialExperience"
                name="ManagerialExperience"
                aria-describedby="ManagerialExperience"
                className="mb-3"
                required={eventType === AEE || eventType === LEE}
              />
            </Form.Group>
          </>
        )}
        {eventType === OE && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="AttendancePurpose">
                Why do you want to attend?
              </Form.Label>
              <Form.Control
                type="text"
                id="AttendancePurpose"
                name="AttendancePurpose"
                aria-describedby="AttendancePurpose"
                className="mb-3"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="AttendanceGain">
                What do you hope to gain from attending the training?
              </Form.Label>
              <Form.Control
                type="text"
                id="AttendanceGain"
                name="AttendanceGain"
                aria-describedby="AttendanceGain"
                className="mb-3"
                required
              />
            </Form.Group>
          </>
        )}
        {eventType === AEE && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="DirectIndirectEmployeesNumber">
                Number of Direct and Indirect Employees Under Your Management
              </Form.Label>
              <Form.Control
                type="number"
                id="DirectIndirectEmployeesNumber"
                name="DirectIndirectEmployeesNumber"
                aria-describedby="DirectIndirectEmployeesNumber"
                className="mb-3"
                required
              />
            </Form.Group>
          </>
        )}
        {eventType === LEE && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="TrainingApproval">
                Training Approval Form (Public Sector Only)
              </Form.Label>
              <Form.Control
                id="TrainingApproval"
                name="TrainingApproval"
                aria-describedby="TrainingApproval"
                type="file"
                accept=".pdf,image/*"
              />
            </Form.Group>
          </>
        )}
        {(eventType === AEE) && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="NominationLetter">
                Nomination Letter
              </Form.Label>
              <Form.Control
                id="NominationLetter"
                name="NominationLetter"
                aria-describedby="NominationLetter"
                type="file"
                accept=".pdf,image/*"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="LevelsFromCEO">
                How many levels between you and the CEO?
              </Form.Label>
              <Form.Select id="LevelsFromCEO" name="LevelsFromCEO" defaultValue={""} required>
                <option value="" disabled></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Form.Select>
            </Form.Group>
          </>
        )}
        {eventType === AEE && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="OrganizationChart">
                Organization Chart
              </Form.Label>
              <Form.Control
                id="OrganizationChart"
                name="OrganizationChart"
                aria-describedby="OrganizationChart"
                type="file"
                accept=".pdf,image/*"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="ValidVisa">Valid Visa</Form.Label>
              <Form.Control
                id="ValidVisa"
                name="ValidVisa"
                aria-describedby="ValidVisa"
                type="file"
                accept=".pdf,image/*"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="Passport">
                Passport (valid for more than 6 months)
              </Form.Label>
              <Form.Control
                id="Passport"
                name="Passport"
                aria-describedby="Passport"
                type="file"
                accept=".pdf,image/*"
                required
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="VaccinationProof">
                Proof of Vaccination (USA Only)
              </Form.Label>
              <Form.Control
                id="VaccinationProof"
                name="VaccinationProof"
                aria-describedby="VaccinationProof"
                type="file"
                accept=".pdf,image/*"
              />
            </Form.Group> */}
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="UniversityApplicationForm">
                University Application Form
              </Form.Label>
              <Form.Control
                id="UniversityApplicationForm"
                name="UniversityApplicationForm"
                aria-describedby="UniversityApplicationForm"
                type="file"
                accept=".pdf,image/*"
                required
              />
            </Form.Group> */}
          </>
        )}
        {eventExtraData?.Questions?.map((question, index) => (
          <Form.Group className="mb-3" key={`questions${index}`}>
            <Form.Label htmlFor={`Answers${index}`}>
              {question}
            </Form.Label>
            <Form.Control
              id={`Answers${index}`}
              name="Answers"
              aria-describedby={`Answers${index}`}
              type="text"
              required
            />
            <Form.Control
              id={`Questions${index}`}
              name="Questions"
              aria-describedby={`Questions${index}`}
              type="hidden"
              value={question}
              required
            />
          </Form.Group>
        ))}
        <div className="bg-light px-3 py-2 rounded mt-5 mb-3">
          {/* ACKNOWLEDGEMENTS */}
          <h4 className="mb-4 text-capitalize">Acknowledgments</h4>
          <p className="d-flex align-items-center">
            You are required to read and accept the
            <Button variant="link" className="p-0 ps-1" onClick={() => setShowModal(true)}>
              Terms and Conditions
            </Button>
          </p>
        </div>
        <Form.Group>
          {checkboxLabels.map((label, i) => (
            <Form.Check
              key={`checkbox-${i}`}
              type="checkbox"
              id={`checkbox-${i}`}
              label={label}
              className="mb-2"
              required
            />
          ))}
        </Form.Group>
        <Form.Control
          type="hidden"
          id="EventId"
          name="EventId"
          value={eventId}
        />
        <Button className="mb-4 m-auto d-block" type="submit">
          Submit
        </Button>
      </Form>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="mb-4">
              {data.termsAndConditions}
            </p>
            {Object.keys(data).map((key) => {
              const dataKey = key as ObjectKey
              return (
                <div key={key} className="mb-4">
                  <h4 className="mb-4 text-primary text-capitalize">{key}</h4>
                  <ListGroup>
                    {data[dataKey].map((text, i) => (
                      <ListGroup.Item key={`${key}-${i}`}>
                        {text}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              )
            })}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EventApplication
