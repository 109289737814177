import React, { useState, useEffect } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import {
  CalendarDate,
  Newspaper,
  PersonCheck,
  People,
  Person,
  Files,
  Clipboard2Data,
  Building,
} from 'react-bootstrap-icons'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import { Col, Container, Row } from 'react-bootstrap'
import { USER_ROLE } from '../../API'

const Dashboard = () => {
  const [role, setRole] = useState('')
  const [loading, setLoading] = useState(true)

  const getUserRole = async () => {
    const rawResponse = await fetch(USER_ROLE)
    setRole(await rawResponse.text())
    setLoading(false)
  }
  useEffect(() => { getUserRole() }, [])

  if (loading) return (<Spinner />)
  return (
    <>
      {/* Admin cards */}
      {role == "admin" && (
        <>
          <h1 className="display-6 mb-4 text-primary text-center text-md-start border-bottom">Admin Dashboard</h1>
          <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
            <Container>
              <Row>
                <Col>
                  <DashboardCard title="Offerings" target="/admin/event-list"><CalendarDate size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Newsfeed" target="/admin/newsfeed-list"><Newspaper size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Organizations" target="/admin/organizations"><People size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Individuals" target="/admin/individuals"><Person size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Reports" target="/admin/reports"><Clipboard2Data size={32} /></DashboardCard>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
      {/* Public  Admin cards */}
      {role == "publicAdmin" && (
        <>
          <h1 className="display-6 mb-4 text-primary text-center text-md-start border-bottom">Admin Dashboard</h1>
          <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
            <Container>
              <Row>
                <Col>
                  <DashboardCard title="Offerings" target="/admin/event-list"><CalendarDate size={32} /></DashboardCard>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
      {/* HR cards */}
      {(role == "representative" || role == "admin") && (
        <>
          <h1 className="display-6 mb-4 text-primary text-center text-md-start border-bottom">HR Dashboard</h1>
          <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
            <Container>
              <Row>
                <Col>
                  <DashboardCard title="Approvals" target="/hr/approvals"><PersonCheck size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Employees" target="/hr/employees/:id"><People size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Organization Profile" target="/hr/profile/:id"><Building size={32} /></DashboardCard>
                </Col>
                <Col>
                  <DashboardCard title="Reports" target="/hr/reports"><Clipboard2Data size={32} /></DashboardCard>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
      {/* User cards */}
      <h1 className="display-6 mb-4 text-primary text-center text-md-start border-bottom">User Dashboard</h1>
      <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
        <Container>
          <Row>
            <Col>
              <DashboardCard title="Profile" target="/profile/:id"><Person size={32} /></DashboardCard>
            </Col>
            <Col>
              <DashboardCard title="Applications" target="/applications"><Files size={32} /></DashboardCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Dashboard
