import * as React from 'react'
import { useEffect, useState } from 'react'
import Register from '../../components/Forms/Organization'
import Spinner from '../../components/Spinner/Spinner'
import {
  OrganizationProfile,
  OrganizationRepresentatives,
} from '../../types/profile'
import { useParams } from 'react-router'
import { ORGANIZATION_PROFILE, ORGANIZATION_REPRESENTATIVES } from '../../API'

const Profile = () => {
  const { id: orgId } = useParams()
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState<OrganizationProfile>()
  const [representatives, setRepresentatives] = useState<OrganizationRepresentatives>()

  const getProfile = async () => {
    const response =
      !orgId || orgId === ':id'
        ? await fetch(ORGANIZATION_PROFILE``)
        : await fetch(ORGANIZATION_PROFILE`${orgId}`)
    const data = await response.json()
    data.Supplementary = JSON.parse(data.Supplementary)
    setProfile(data)
  }
  const getRepresentatives = async () => {
    const response =
      !orgId || orgId === ':id'
        ? await fetch(ORGANIZATION_REPRESENTATIVES``)
        : await fetch(ORGANIZATION_REPRESENTATIVES`${orgId}`)
    const data = await response.json()
    setRepresentatives(data)
  }
  useEffect(() => {
    getProfile()
    getRepresentatives()
    setLoading(false)
  }, [])

  if (loading) return (<Spinner />)
  return (
    <Register
      title="Profile"
      isEditing={true}
      profile={profile}
      representatives={representatives}
    />
  )
}

export default Profile