import './Home.css'
import * as React from 'react'
import { useState, useEffect } from 'react'
import Hero from '../components/Home/Hero'
import Events from '../components/Home/Events'
import NewsFeed from '../components/Home/NewsFeed'
// import Partners from '../components/Home/Partners'
import Spinner from '../components/Spinner/Spinner'
import { Article } from '../types/article'
import { Event } from '../types/event'
import { HOME_ARTICLES, PUBLIC_EVENTS } from '../API'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState<Article[]>([])
  const [events, setEvents] = useState<Event[]>([])
  useEffect(() => {
    const getData = async () => {
      try {
        const [eventsData, articlesData] = await Promise.all([
          fetch(PUBLIC_EVENTS`4`).then((response) =>
            response.json()
          ),
          fetch(HOME_ARTICLES).then((response) =>
            response.json()
          ),
        ])
        setArticles(articlesData as Article[])
        setEvents(eventsData as Event[])
        setLoading(false)
      } catch (e) { }
    }
    getData()
  }, [])
  if (loading) return (<Spinner />)
  return (
    <div className="home">
      <Hero />
      <Events events={events} />
      <NewsFeed articles={articles} />
      {/* <Partners /> */}
    </div>
  )
}

export default Home
